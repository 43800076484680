import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { auth } from '../firebase'; // Firebase Auth
import Dashboard from './Dashboard';
import backgroundImage from '../images/aicam1.jpg';

const HomePage = () => {
  const [user, setUser] = useState(null);

  // Check authentication state on component mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); // Set user if logged in, otherwise null
    });
    return () => unsubscribe(); // Cleanup on unmount
  }, []);

  return (
    <div>
      {/* Background Section with title, subtitle, and buttons */}
      <Box sx={styles.background}>
        <Container maxWidth="md" sx={styles.container}>
          <Typography variant="h4" sx={styles.title}>
          Security Network with  <span style={styles.susnetText}>Secuall™</span>
          </Typography>
          <Typography variant="body1" sx={styles.subtitle}>
            Secure your home and Car with a leading-edge homecam and dashcam branidng as Secuall to avoid suspicious activities.
          </Typography>
          <Box sx={styles.buttons}>
            {user ? (
              // Welcome message displayed when user is logged in
              <Typography variant="h6" sx={styles.welcomeMessage}>
                Welcome, {user.displayName || user.email}!
              </Typography>
            ) : (
              // Sign In and Sign Up buttons for non-authenticated users
              <>
                <Button
                  variant="contained"
                  color="primary"
                  sx={styles.button}
                  component={Link}
                  to="/login"
                >
                  Sign In
                </Button>
                <Button
                  variant="outlined"
                  sx={styles.signupButton}
                  component={Link}
                  to="/signup"
                >
                  Sign Up
                </Button>
              </>
            )}
          </Box>
        </Container>
      </Box>

      {/* Dashboard section directly connected to the background */}
      <Dashboard />
    </div>
  );
};

const styles = {
  background: {
    height: '70vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0',
    paddingBottom: '0',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    marginBottom: '1rem',
    fontWeight: 'bold',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '2.5rem',
  },
  susnetText: {
    color: 'orange',
  },
  subtitle: {
    marginBottom: '2rem',
    color: '#ffffff',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.25rem',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  button: {
    padding: '0.8rem 1.5rem',
  },
  signupButton: {
    padding: '0.8rem 1.5rem',
    borderColor: 'orange',
    color: 'orange',
    '&:hover': {
      backgroundColor: 'rgba(255, 165, 0, 0.1)',
    },
  },
  welcomeMessage: {
    color: '#ffffff',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};

export default HomePage;

