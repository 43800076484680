import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Typography, Box, Snackbar, Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Import Firebase Auth
import logo from '../images/logo1.jpg';

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [logoutSnackbar, setLogoutSnackbar] = useState(false);
  const navigate = useNavigate();

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe(); // Clean up on unmount
  }, []);

  // Logout function
  const handleLogout = async () => {
    await auth.signOut();
    setUser(null); // Clear user state after logout
    setLogoutSnackbar(true); // Show logout success message
    navigate('/'); // Redirect to home page
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setLogoutSnackbar(false);
  };

  return (
    <>
      <AppBar position="static" sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          <Box sx={styles.logoContainer}>
            <Link to="/">
              <img src={logo} alt="Logo" style={styles.logo} />
            </Link>
            <Typography variant="h6" sx={styles.title}>
              {/* Optional title text */}
            </Typography>
          </Box>
          <Box sx={styles.buttonGroup}>
            <Button component={Link} to="/" sx={styles.button}>
              Home
            </Button>
            {!user ? (
              <>
                <Button component={Link} to="/login" sx={styles.button}>
                  Login
                </Button>
                <Button component={Link} to="/signup" sx={styles.button}>
                  Sign Up
                </Button>
              </>
            ) : (
              <>
                <Button component={Link} to="/dashboard" sx={styles.button}>
                  Dashboard
                </Button>
                <Button onClick={handleLogout} sx={styles.button}>
                  Logout
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Snackbar for Logout Success Message */}
      <Snackbar
        open={logoutSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Logged out successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

const styles = {
  appBar: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '40px',
    height: '40px',
    marginRight: '1rem',
    cursor: 'pointer',
  },
  title: {
    color: '#000',
    fontWeight: 600,
  },
  buttonGroup: {
    display: 'flex',
  },
  button: {
    marginLeft: '1rem',
    color: '#000',
  },
};

export default Navbar;
